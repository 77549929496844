import { Container, Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import React from "react"
import { Outlet, useParams } from "react-router-dom"
import useUserType from "views/auth/useUserType"

const SchoolRoot = () => {
  const { schoolId } = useParams() as { schoolId: string }

  const { isSchoolAdmin } = useUserType()

  const school = useQuery({
    enabled: !!schoolId,
    queryKey: ["schoolProfile", schoolId],
    queryFn: () =>
      api.profile.retrieve({
        urlParams: {},
        headers: {
          "School-Id": schoolId,
        },
      }),
  })

  return (
    <>
      {!isSchoolAdmin && (
        <div className="bg-interactive-500 py-1 text-white">
          <Container>
            <Typography variant="title3">{school.data?.name}</Typography>
          </Container>
        </div>
      )}
      <Outlet />
    </>
  )
}

export default SchoolRoot
