import { Button, IconButton, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import ShareJobButton from "components/jobs/ShareJobButton"
import { formatDistance } from "date-fns"
import { ShareAndroid } from "iconoir-react"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router"
import { routes } from "utils/routes"
import ProfileContext from "views/Profile/context"

const useStyles = createUseStyles(theme => ({
  container: {
    borderRadius: theme.spacing(1),
    width: "100%",
    border: `1px solid ${theme.colors.surface[200]}`,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
  },
  bottomContainer: {
    borderTop: `1px solid ${theme.colors.surface[200]}`,
  },
}))

function JobCard({
  position,
  salaryOffered = 0,
  maximumSalaryOffered = 0,
  subject = "",
  datePublished,
  currencyCode = "INR",
  schoolSlug = "",
  slug = "",
  id,
  salaryNegotiable,
  previewMode = false,
  schoolId,
}: {
  slug?: string
  schoolSlug?: string
  position: string
  salaryOffered: number | null
  maximumSalaryOffered: number | null
  subject?: string
  datePublished?: string
  id: number
  currencyCode?: string
  previewMode?: boolean
  salaryNegotiable: boolean
  schoolId: string
}) {
  const classes = useStyles()
  const { isPublic } = useContext(ProfileContext)
  const navigate = useNavigate()

  const datePublishStatus =
    datePublished &&
    formatDistance(new Date(datePublished), new Date(), {
      addSuffix: true,
    })

  return (
    <div className={clsx("flex flex-col justify-between", classes.container)}>
      <div className="flex p-2">
        <div className="flex grow flex-col gap-0.5">
          <Typography variant="strong">{position}</Typography>
          {salaryNegotiable ? (
            <Typography>Salary Negotiable</Typography>
          ) : (
            <div className="flex flex-wrap items-center">
              <Typography variant="strong">
                {currencyCode} {Number(salaryOffered).toLocaleString()}{" "}
                {maximumSalaryOffered && maximumSalaryOffered > 0 ? (
                  <>- {Number(maximumSalaryOffered).toLocaleString()}</>
                ) : null}
              </Typography>
              <Typography color="onSurface.500" variant="body">
                /year
              </Typography>
            </div>
          )}
          <Typography color="onSurface.500" variant="body">
            {subject}
          </Typography>
        </div>
        {!previewMode && !isPublic && (
          <div>
            <ShareJobButton
              activator={handleOpen => (
                <IconButton onClick={handleOpen}>
                  <ShareAndroid />
                </IconButton>
              )}
              jobSlug={slug}
              position={position}
              schoolSlug={schoolSlug}
              subject={subject}
            />
          </div>
        )}
      </div>
      <div
        className={clsx(
          "flex items-center justify-between px-2 py-1",
          classes.bottomContainer
        )}
      >
        <Typography color="onSurface.500" variant="smallBody">
          {datePublished &&
            `Posted ${
              (datePublishStatus !== "less than a minute ago"
                ? datePublishStatus
                : "recently") ?? ""
            }`}
        </Typography>
        {previewMode ? (
          <Button size="sm">Apply</Button>
        ) : (
          <Button
            size="sm"
            variant={isPublic ? "outlined" : "text"}
            onClick={() =>
              navigate(
                routes.school.job.details
                  .replace(":schoolId", schoolId.toString())
                  .replace(":jobId", id.toString())
              )
            }
          >
            {isPublic ? "Apply" : "View"}
          </Button>
        )}
      </div>
    </div>
  )
}

export default JobCard
