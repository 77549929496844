import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Job, JobStatus } from "api/resources/jobs/types"
import clsx from "clsx"
import { GlobalContext } from "components/GlobalState"
import CloseJobDialog from "components/jobs/CloseJobDialog"
import ReopenJobConfirmationDialog from "components/jobs/ReopenJobConfirmationDialog"
import ShareJobButton from "components/jobs/ShareJobButton"
import { format } from "date-fns"
import {
  EditPencil,
  MoreVert,
  OffTag,
  OnTag,
  ShareAndroid,
} from "iconoir-react"
import { useContext, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link, useNavigate } from "react-router-dom"
import { routes } from "utils/routes"
import toast from "utils/toast"
import useUserType from "views/auth/useUserType"

const useStyles = createUseStyles(theme => ({
  root: {
    background: "white",
    border: `1px solid ${theme.colors.surface[200]}`,
    borderRadius: "4px",
  },
  shareButton: {
    cursor: "pointer",
    display: "flex",
    gap: theme.spacing(1),
    padding: "10px 8px",
    margin: "0px 8px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: theme.colors.onSurface[100],
    },
    "&:active": {
      backgroundColor: theme.colors.onSurface[200],
    },
    "& span": {
      width: "auto",
    },

    "&>svg": {
      width: "20px",
      height: "20px",
    },
  },
  shareMenuItem: {
    cursor: "default",
    margin: "0px !important",
    padding: "0px !important",
    "&:hover": {
      background: "none",
    },
    "&:active": {
      background: "none",
    },
  },
}))

type Props = {
  job: Job
  afterSubmit: () => void
  schoolId: string | undefined
  canUpdateJob: boolean
  canCloseJob: boolean
}

const JobPostingCard = ({
  job,
  afterSubmit,
  schoolId,
  canUpdateJob,
  canCloseJob,
}: Props) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const { isLeadAdmin } = useUserType()

  const { profile } = useContext(GlobalContext)

  const [isClosingJob, setIsClosingJob] = useState(false)
  const [isReopeningJob, setIsReopeningJob] = useState(false)

  const toggleReopenJobDialog = () => {
    setIsReopeningJob(false)
  }

  const onSubmitReopenJob = async () => {
    const res = await api.jobs.update({
      urlParams: { jobId: job.id },
      data: {
        status: JobStatus.PUBLISHED,
      },
      headers: {
        "School-Id": schoolId,
      },
    })
    if (res.isSuccessful) {
      toast.success("Job Reopen successfully")
      setIsReopeningJob(false)
      afterSubmit()
    } else {
      toast.error(res.errors.message)
    }
  }

  const actionButton = () => {
    if (job.status === JobStatus.CLOSED) {
      if (!schoolId) {
        return null
      }
      return (
        <Button
          component={Link}
          to={`${routes.school.job.details
            .replace(":schoolId", schoolId.toString())
            .replace(":jobId", job.id.toString())}`}
          variant="text"
        >
          View Applications
        </Button>
      )
    }
    if (job.numberOfApplicants === 0 && !isLeadAdmin && schoolId) {
      return (
        <Button
          component={Link}
          to={`${routes.explore}?jobId=${job.id}&schoolId=${schoolId}&jobPosition=${job.position}`}
          variant="text"
        >
          Invite Teachers
        </Button>
      )
    }
    if (!schoolId) {
      return null
    }
    return (
      <Button
        component={Link}
        to={`${routes.school.job.details
          .replace(":schoolId", schoolId.toString())
          .replace(":jobId", job.id.toString())}?tab=Active+Applications`}
        variant="text"
      >
        View Applications
      </Button>
    )
  }
  const closedJobClass = "sm:col-span-2"
  const openJobClass = "sm:col-span-3"

  return (
    <>
      <div
        className={clsx(
          "mb-1 grid grid-cols-12 items-center gap-y-1 p-1.5 sm:gap-y-0 sm:p-3",
          classes.root
        )}
      >
        <div className="col-span-12 sm:col-span-3">
          <Typography variant="strong">{job.position}</Typography>
          <Typography variant="smallBody">{job.subject.name}</Typography>
        </div>
        <div
          className={clsx("col-span-6", {
            [openJobClass]: !job.dateClosed,
            [closedJobClass]: job.dateClosed,
          })}
        >
          {job.salaryNegotiable ? (
            <Typography variant="smallBody">Salary Negotiable</Typography>
          ) : (
            job.currency &&
            job.salaryOffered && (
              <>
                <Typography
                  style={{ overflowWrap: "break-word" }}
                  variant="strong"
                >
                  {job.currency.code} {job.salaryOffered.toLocaleString()}{" "}
                  {job.maximumSalaryOffered && (
                    <span>
                      - {job.currency.code}{" "}
                      {job.maximumSalaryOffered.toLocaleString()}
                    </span>
                  )}
                </Typography>
                <Typography variant="smallBody">per year</Typography>
              </>
            )
          )}
        </div>
        <div
          className={clsx("col-span-6", {
            [openJobClass]: !job.dateClosed,
            [closedJobClass]: job.dateClosed,
          })}
        >
          {job.datePublished && (
            <>
              <Typography
                style={{ overflowWrap: "break-word" }}
                variant="strong"
              >
                {format(new Date(job.datePublished), "d MMM, yyyy")}
              </Typography>
              <Typography variant="smallBody">posted on</Typography>
            </>
          )}
        </div>
        {job.dateClosed && (
          <div className="col-span-6 sm:col-span-2">
            <Typography style={{ overflowWrap: "break-word" }} variant="strong">
              <>
                <Typography
                  style={{ overflowWrap: "break-word" }}
                  variant="strong"
                >
                  {format(new Date(job.dateClosed), "d MMM, yyyy")}
                </Typography>
                <Typography variant="smallBody">closed on</Typography>
              </>
            </Typography>
          </div>
        )}
        <div className="col-span-6 flex justify-end gap-4 sm:col-span-3">
          {actionButton()}
          <div>
            {job.status === JobStatus.CLOSED && !isLeadAdmin ? (
              <Menu
                menuButton={
                  <IconButton variant="plain">
                    <MoreVert />
                  </IconButton>
                }
              >
                <MenuItem
                  startAdornment={<OnTag />}
                  onClick={() => {
                    setIsReopeningJob(true)
                  }}
                >
                  Reopen Job
                </MenuItem>
              </Menu>
            ) : (
              <Menu
                menuButton={
                  <IconButton variant="plain">
                    <MoreVert />
                  </IconButton>
                }
              >
                {canUpdateJob && schoolId && (
                  <MenuItem
                    startAdornment={<EditPencil />}
                    onClick={() => {
                      navigate(
                        routes.school.job.edit
                          .replace(":jobId", String(job.id))
                          .replace(":schoolId", schoolId)
                      )
                    }}
                  >
                    Edit Job
                  </MenuItem>
                )}

                <MenuItem className={classes.shareMenuItem}>
                  <ShareJobButton
                    activator={handleOpen => (
                      <div
                        className={classes.shareButton}
                        role="button"
                        tabIndex={0}
                        onClick={(e: any) => {
                          e.stopPropagation()
                          handleOpen()
                        }}
                        onKeyDown={() => {}}
                      >
                        <ShareAndroid />

                        <span>Share</span>
                      </div>
                    )}
                    jobSlug={job.slug}
                    position={job.position}
                    schoolSlug={profile?.slug || ""}
                    subject={job.subject.name}
                  />
                </MenuItem>
                {canCloseJob && (
                  <MenuItem
                    color="critical"
                    startAdornment={<OffTag />}
                    onClick={() => {
                      setIsClosingJob(true)
                    }}
                  >
                    Close Job
                  </MenuItem>
                )}
              </Menu>
            )}
          </div>
        </div>
      </div>
      <CloseJobDialog
        open={isClosingJob}
        position={job.position}
        jobId={job.id}
        subjectName={job.subject?.name}
        handleClose={() => setIsClosingJob(false)}
        onSuccess={afterSubmit}
        schoolId={schoolId || ""}
      />
      <ReopenJobConfirmationDialog
        open={isReopeningJob}
        position={job.position}
        subjectName={job.subject.name}
        toggle={toggleReopenJobDialog}
        onSubmit={onSubmitReopenJob}
      />
    </>
  )
}

export default JobPostingCard
