import {
  Button,
  CircularProgress,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { Job, JobStatus } from "api/resources/jobs/types"
import clsx from "clsx"
import { GlobalContext } from "components/GlobalState"
import ActiveApplicationsTab from "components/jobs/ActiveApplicationsTab"
import ApplicantsTab from "components/jobs/ApplicantsTab"
import CloseJobDialog from "components/jobs/CloseJobDialog"
import HiredTab from "components/jobs/HiredTab"
import InvitedTab from "components/jobs/InvitedTab"
import JobOpeningDetailsDialog from "components/jobs/JobOpeningDetailsDialog"
import NotHiredTab from "components/jobs/NotHiredTab"
import ReopenJobConfirmationDialog from "components/jobs/ReopenJobConfirmationDialog"
import ShareJobButton from "components/jobs/ShareJobButton"
import Tabs from "components/jobs/Tabs"
import BackButton from "components/shared/BackButton"
import Navbar from "components/shared/Navbar"
import { NavArrowDown, OpenInWindow, ShareAndroid } from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link, useParams } from "react-router-dom"
import useTabs, { Tabs as TabsType } from "utils/hooks/useTabs"
import useToggle from "utils/hooks/useToggle"
import { routes } from "utils/routes"
import toast from "utils/toast"
import { useSchoolActions } from "views/auth/useSchoolActions"
import { Actions } from "views/auth/useUserActions"
import NoAccess from "views/common/NoAccess"

const useStyles = createUseStyles(theme => ({
  root: {
    background: "white",
    borderBottom: `1px solid ${theme.colors.surface[200]}`,
  },
  inviteButton: {
    borderRadius: "4px 0px 0px 4px",
    marginRight: "2px",
  },
  splitButton: {
    "& a": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& button": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}))

const activeApplication: TabsType = [
  {
    name: "Active Applications",
    content: ActiveApplicationsTab,
  },
  {
    name: "Applicants",
    content: ApplicantsTab,
  },
  {
    name: "Invited",
    content: InvitedTab,
  },
  {
    name: "Hired",
    content: HiredTab,
  },
  {
    name: "Not Hired",
    content: NotHiredTab,
  },
]

const MoreDropdown = ({ onCloseJob = () => {} }) => {
  return (
    <Menu
      menuButton={
        <Button size="sm" className="!rounded-l-none !px-1.5">
          <NavArrowDown />
        </Button>
      }
      align="end"
    >
      <MenuItem onClick={onCloseJob} color="critical">
        Close Job
      </MenuItem>
    </Menu>
  )
}

const Details = () => {
  const classes = useStyles()

  const { profile } = useContext(GlobalContext)

  const { jobId, schoolId = "" } = useParams()
  const { hasSchoolAction } = useSchoolActions({
    schoolIds: [schoolId],
  })
  const canManageHiringProcess = hasSchoolAction(
    Actions.manageHiringProcess,
    schoolId
  )
  const canPushProfilesToSchool = hasSchoolAction(
    Actions.pushProfilesToSchool,
    schoolId
  )
  const canViewJob = hasSchoolAction(Actions.viewJob, schoolId)

  const [jobOpeningDetailsDialog, toggleJobOpeningDetailsDialog] =
    useToggle(false)
  // const [jobOverviewCount, setJobOverviewCount] =
  //   useState<JobOverviewCount | null>(null)
  const [job, setJob] = useState<Job | null>(null)
  const [tabDetails, setTabDetails] = useState<TabsType>(activeApplication)
  const [loading, setLoading] = useState(false)
  const [isReopeningJob, setIsReopeningJob] = useState(false)
  const [showPushProfileConfirmSheet, setShowPushProfileConfirmSheet] =
    useState(false)
  const [showSelectProfiles, setShowSelectProfiles] = useState(false)
  const [showCloseJobDialog, setShowCloseJobDialog] = useState(false)

  const [activeTab, setActiveTab] = useTabs({
    tabs: tabDetails,
    initialTab: tabDetails[0].name,
  })
  const toggleReopenJobDialog = () => {
    setIsReopeningJob(false)
  }

  const onSubmitReopenJob = async () => {
    if (jobId) {
      const res = await api.jobs.update({
        urlParams: { jobId },
        data: {
          status: "published",
          closingReason: null,
        },
        headers: {
          "School-Id": schoolId,
        },
      })
      if (res.isSuccessful) {
        toast.success("Job Reopen successfully")
        setIsReopeningJob(false)
        window.location.reload()
      } else {
        toast.error(res.errors.message)
      }
    }
  }
  const onPushProfileClick = async () => {
    setShowSelectProfiles(true)
  }

  const actionButton = (job: Job) => {
    if (!canManageHiringProcess) {
      return (
        <div>
          <ShareJobButton
            activator={handleOpen => (
              <IconButton size="md" variant="filled" onClick={handleOpen}>
                <ShareAndroid />
              </IconButton>
            )}
            jobSlug={job.slug}
            position={job.position}
            schoolSlug={profile?.slug || ""}
            subject={job.subject.name}
          />
        </div>
      )
    }
    if (job.status === JobStatus.CLOSED) {
      return (
        <Button
          onClick={() => {
            setIsReopeningJob(true)
          }}
        >
          Reopen Job
        </Button>
      )
    }
    if (showSelectProfiles) {
      return (
        <div className="flex gap-1">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setShowSelectProfiles(false)}
          >
            Cancel
          </Button>
          <Button onClick={() => setShowPushProfileConfirmSheet(true)}>
            Send Profiles
          </Button>
        </div>
      )
    }
    return (
      <div className={"flex gap-0.25"}>
        {schoolId && (
          <Button
            component={Link}
            to={`${routes.explore}?jobId=${job.id}&schoolId=${schoolId}&jobPosition=${job.position}`}
            className="!rounded-r-none"
          >
            Invite Teachers
          </Button>
        )}
        {activeTab.name === "Applicants" && canPushProfilesToSchool && (
          <Button onClick={onPushProfileClick} className="!rounded-none">
            Push Profiles
          </Button>
        )}
        <ShareJobButton
          activator={handleOpen => (
            <IconButton
              size="md"
              variant="filled"
              onClick={handleOpen}
              className="!rounded-none"
            >
              <ShareAndroid />
            </IconButton>
          )}
          jobSlug={job.slug}
          position={job.position}
          schoolSlug={profile?.slug || ""}
          subject={job.subject.name}
        />
        <MoreDropdown onCloseJob={() => setShowCloseJobDialog(true)} />
      </div>
    )
  }

  const jobOverview = useQuery({
    enabled: Boolean(jobId),
    queryFn: () =>
      api.jobs.overviewCount.fn({
        urlParams: {
          jobId: jobId!,
        },
        headers: {
          "School-Id": schoolId,
        },
      }),
    queryKey: api.jobs.overviewCount.key(jobId!.toString()),
  })

  useEffect(() => {
    if (jobOverview.data) {
      const { activeApplications, applicants, hired, invited, notHired } =
        jobOverview.data
      setTabDetails(() => [
        {
          name: "Active Applications",
          content: ActiveApplicationsTab,
          count: activeApplications,
        },
        {
          name: "Applicants",
          content: ApplicantsTab,
          count: applicants,
        },
        {
          name: "Invited",
          content: InvitedTab,
          count: invited,
        },
        {
          name: "Hired",
          content: HiredTab,
          count: hired,
        },
        {
          name: "Not Hired",
          content: NotHiredTab,
          count: notHired,
        },
      ])
    }
  }, [jobOverview.data])

  const retrieveJob = async () => {
    if (!jobId || !schoolId) return
    setLoading(true)
    const res = await api.jobs.retrieve({
      urlParams: {
        jobId,
      },
      headers: {
        "School-Id": schoolId,
      },
    })

    if (res.isSuccessful) {
      setJob(res.data)
      // setTabDetails(currentTabType(res.data))
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    retrieveJob()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, schoolId])

  useEffect(() => {
    if (activeTab.name !== "Applicants" && showSelectProfiles) {
      setShowSelectProfiles(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab.name])

  if (!jobId || !job) return null

  if (!canViewJob) {
    return (
      <>
        <Navbar gutterBottom={false} />

        <NoAccess />
      </>
    )
  }
  return (
    <>
      <JobOpeningDetailsDialog
        data={job}
        open={jobOpeningDetailsDialog}
        toggle={toggleJobOpeningDetailsDialog}
      />
      <Navbar gutterBottom={false} hideBackButton />

      <div className={clsx(classes.root, "pb-1.5")}>
        <Container>
          <BackButton className="my-2" nudge="left" />
          {loading ? (
            <div className="mt-5 flex justify-center">
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div className="mb-4 mt-2 flex items-center justify-between">
                <div>
                  <div className="flex gap-1">
                    <Typography variant="title2">{job.position}</Typography>
                    <IconButton
                      color="black"
                      onClick={() => toggleJobOpeningDetailsDialog()}
                    >
                      <OpenInWindow />
                    </IconButton>
                  </div>
                  <Typography color="onSurface.500" variant="smallBody">
                    {job.subject.name}
                  </Typography>
                </div>
                {job && actionButton(job)}
              </div>
              <div className="flex justify-between gap-2">
                <Tabs
                  activeTab={activeTab}
                  tabs={tabDetails}
                  onChange={setActiveTab}
                />
              </div>
            </div>
          )}
        </Container>
      </div>
      <Container className="mb-3">
        <activeTab.content
          isClosed={job.status === JobStatus.CLOSED}
          job={job}
          isPreviousApplications={job.status === JobStatus.CLOSED}
          showPushProfileSheet={showPushProfileConfirmSheet}
          onHidePushProfileSheet={(success?: boolean) => {
            setShowPushProfileConfirmSheet(false)

            if (success) {
              setShowSelectProfiles(false)
            }
          }}
          showSelectProfiles={showSelectProfiles}
          showShared
          schoolId={schoolId}
          canManageHiringProcess={canManageHiringProcess}
        />
      </Container>
      <ReopenJobConfirmationDialog
        open={isReopeningJob}
        position={job.position}
        subjectName={job.subject.name}
        toggle={toggleReopenJobDialog}
        onSubmit={onSubmitReopenJob}
      />
      <CloseJobDialog
        open={showCloseJobDialog}
        position={job.position}
        jobId={job.id}
        subjectName={job.subject?.name}
        handleClose={() => setShowCloseJobDialog(false)}
        onSuccess={async () => {
          setLoading(true)
          await new Promise(rs => setTimeout(rs, 2000))
          retrieveJob()
        }}
        schoolId={schoolId}
      />
    </>
  )
}
export default Details
