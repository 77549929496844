import { Button, Divider, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { Job } from "api/resources/jobs/types"
import clsx from "clsx"
import { ArrowDown, Plus } from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router"
import { tabsProfile } from "utils/constants"
import { routes } from "utils/routes"
import toast from "utils/toast"
import { useSchoolActions } from "views/auth/useSchoolActions"
import { Actions } from "views/auth/useUserActions"
import ProfileContext from "views/Profile/context"

import JobCard from "./JobCard"

const useStyles = createUseStyles(theme => ({
  container: {
    background: theme.colors.common.white[500],
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.colors.surface[200]}`,
  },
}))

function JobOpenings({ schoolId = "" }: { schoolId: string }) {
  const classes = useStyles()

  const { hasSchoolAction } = useSchoolActions({
    schoolIds: [schoolId],
  })

  const {
    isPublic,
    profile: { slug },
  } = useContext(ProfileContext)
  const navigate = useNavigate()
  const [showAll, setShowAll] = useState(false)
  const [jobList, setJobList] = useState<Job[]>([])
  const [loading, setLoading] = useState(false)
  const [totalJobs, setTotalJobs] = useState<number | null>(0)

  useEffect(() => {
    if (!schoolId) {
      return
    }
    if (showAll) {
      setLoading(true)
    }

    async function getJobs() {
      const res = await api.jobs.list({
        params: {
          fields: ["date_published"],
          size: 6,
          page: showAll ? "all" : "1",
        },
        headers: {
          "School-Id": schoolId,
        },
      })

      if (res.isSuccessful) {
        setTotalJobs(res.data.total)
        setJobList(res.data.data)
      } else {
        toast.error(res.errors.message)
      }
      setLoading(false)
    }
    getJobs()
  }, [showAll, schoolId])
  const canEdit = schoolId && hasSchoolAction(Actions.createJob, schoolId)

  return (
    <div
      className={clsx("mt-3 px-2 py-3.5 sm:px-5 sm:py-4", classes.container)}
      id={tabsProfile.jobs}
    >
      <div className="mb-4 flex items-center justify-between">
        <Typography variant="title3">Job Openings</Typography>
        {!isPublic && canEdit && (
          <Button
            startAdornment={<Plus />}
            variant="text"
            onClick={() =>
              navigate(routes.school.job.create.replace(":schoolId", schoolId))
            }
          >
            Create New Job Opening
          </Button>
        )}
      </div>

      {jobList.length ? (
        <>
          <div className="grid gap-3 sm:grid-cols-2 lg:grid-cols-3">
            {jobList.map(item => (
              <JobCard
                salaryNegotiable={item.salaryNegotiable}
                currencyCode={item.currency?.code}
                datePublished={item.datePublished}
                id={item.id}
                key={item.id}
                maximumSalaryOffered={item.maximumSalaryOffered}
                position={item.position}
                salaryOffered={item.salaryOffered}
                schoolSlug={slug}
                slug={item.slug}
                subject={item.subject.name}
                schoolId={schoolId || ""}
              />
            ))}
          </div>

          {((totalJobs && totalJobs > 6) || loading) && (
            <>
              <Divider className="mb-0.5 mt-3" color="onSurface.200" />
              <div className="flex justify-end">
                <Button
                  color="black"
                  endAdornment={<ArrowDown />}
                  loading={loading}
                  variant="text"
                  onClick={() => setShowAll(true)}
                >
                  View All Job Openings
                </Button>
              </div>
            </>
          )}
        </>
      ) : (
        <Typography
          className="mb-0.5"
          color="onSurface.400"
          variant="smallBody"
        >
          {canEdit
            ? "You haven’t created any Job Openings."
            : "Job Openings not available at the moment."}
        </Typography>
      )}
    </div>
  )
}

export default JobOpenings
