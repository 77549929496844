import { Country, Curriculum, State } from "../global/types"

export enum WorkingDays {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export enum TeachingMode {
  ONLINE = "online",
  CLASSROOM = "classroom",
  BLENDED = "blended",
}

export type Gallery = { id: number; photo: string }

export type Amenity = { name: string; id: string }

export type Perk = { name: string; id: string }

export type ManagementMessage = {
  id: number
  name: string
  image: string | null
  message: string
  position: string
  school: number
}

export type Profile = {
  id: number
  name: string
  slug: string
  website: string
  email: string
  phoneNumber: {
    countryCode: number
    number: number
  } | null
  address: string
  description: string
  logo: string | null
  city: string | null
  branch: string | null
  pincode: number | null
  coverImage: string | null
  dateEstablished: string | null
  workingDays: WorkingDays[] | null
  dayStartTime: string | null
  dayEndTime: string | null
  teachingMode: TeachingMode | null
  isVerified: boolean
  managementMessage?: ManagementMessage | null
  curriculumBoard?: Curriculum[]
  galleryCount: number
  gallery?: Gallery[]
  perks?: Perk[]
  amenities?: Amenity[]
  country: Country | null
  state: State | null
  schoolContactPerson?: {
    id: number
    phoneNumber: {
      countryCode: number | string
      number: number | string
    }
    name: string
    email: string
  } | null
}

export type GalleryItem = {
  id: number
  photo: string
  title: string
  school: number
}
